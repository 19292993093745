import { PriceInfoType } from "@tential/ec-gql-schema/types/cart"

export const calcPrice = (price: number, count = 1, tax = 10): PriceInfoType => {
  const taxRate = tax || 10
  return {
    price_exclude_tax: price * count,
    price: (price * count * (100 + taxRate)) / 100,
    reference_price: Math.floor((price * count * (100 + taxRate)) / 100), // 参考価格
  }
}

/** 金額と個数と税率をかける 端数ありコンマなし */
export const priceWithTax = (price: number, tax?: number, count?: number): number => {
  return calcPrice(price, count, tax).price
}

/** 金額と個数と税率をかけて端数を切り捨てコンマを追加する 個数が1の場合はcountを渡さないようにする */
export const priceWithTaxAndComma = (price: number, tax?: number, count?: number): string => {
  return calcPrice(price, count, tax).reference_price.toLocaleString()
}

/** 税込金額から消費税のみを取得する */
export const priceOnlyTax = (price: number, _tax = 10): number => {
  const tax = (_tax || 10) / 100

  return Math.floor((price / (1 + tax)) * tax)
}
